<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-3">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p>Sign in to continue to <br>Command & Planning 4.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  alt="Logo"
                  class="img-fluid pb-3"
                  src="@/assets/images/logo.png"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img alt="Logo" height="34" src="@/assets/images/logo.svg"/>
                  </span>
                </div>
              </router-link>
            </div>
            <b-alert
              v-model="isAuthError"
              class="mt-3"
              dismissible
              variant="danger"
            >{{ authError }}
            </b-alert
            >
            <div
              v-if="notification.message"
              :class="'alert ' + notification.type"
            >
              {{ notification.message }}
            </div>

            <b-form class="p-2" @submit.prevent="tryToLogIn">
              <b-form-group
                id="input-group-1"
                class="mb-3"
                label="Username or Email"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="user_name"
                  :class="{
                    'is-invalid': submitted && v$.user_name.$error,
                  }"
                  placeholder="Enter email"
                  type="text"
                ></b-form-input>
                <div
                  v-for="(item, index) in v$.user_name.$errors"
                  :key="index"
                  class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                class="mb-3"
                label="Password"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="password"
                  :class="{
                    'is-invalid': submitted && v$.password.$error,
                  }"
                  placeholder="Enter password"
                  type="password"
                ></b-form-input>
                <div
                  v-if="submitted && v$.password.$error"
                  class="invalid-feedback"
                >
                  <span v-if="v$.password.required.$message">{{
                      v$.password.required.$message
                    }}</span>
                </div>
              </b-form-group>
              <b-form-checkbox
                id="customControlInline"
                class="form-check me-2 mt-0"
                name="checkbox-1"
                unchecked-value="not_accepted"
                value="accepted"
              >Remember me
              </b-form-checkbox>
              <div class="mt-3 d-grid">
                <b-button class="btn-block" type="submit" variant="primary"
                >Log In
                </b-button
                >
              </div>
              <div class="mt-4 text-center">
                <router-link class="text-muted" to="/forgot-password">
                  <i class="mdi mdi-lock me-1"></i> Forgot your password?
                </router-link>
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            © {{ new Date().getFullYear() }} Omnigo Software
          </p>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
<script>
import axios from 'axios';
import { showLoading, showMessage } from '@/components/widgets/swalUtils';
import {
  authComputed,
  authMethods,
  notificationMethods,
  incidentsMethods,
} from '@/state/helpers';

import appConfig from '@/app.config.json';
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Layout from '../../layouts/auth.vue';

/**
 * Login component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: 'Login',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      user_name: '',
      password: '',
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  validations: {
    user_name: {
      required: helpers.withMessage('Email or username is required', required),
    },
    password: {
      required: helpers.withMessage('Password is required', required),
    },
  },
  computed: {
    ...authComputed,
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    ...incidentsMethods,
    async tryToLogIn() {
      this.submitted = true;
      this.v$.$touch();

      if (this.v$.$invalid) {
        showMessage({
          text: 'Incorrect username or password entered. Please try again.',
          icon: 'error',
        });
      } else {
        showLoading('Logging in...');

        await axios.get('sanctum/csrf-cookie').then(async () => {
          const login = await this.logIn({ user_name: this.user_name, password: this.password });
          if (login) {
            const redirect = this.$route?.query?.redirectFrom;
            window.location.href = redirect || '/';
          }
        }).catch((error) => {
          showMessage({
            title: 'Login failed',
            text: error.message,
            icon: 'error',
          });
        });
      }
    },
  },
};
</script>
